import { PageProps } from 'gatsby';
import { QuestionData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Question';
import List from '~/containers/Espace/List';
import question from '~/params/question.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useQuestions from '~/utils/useQuestions';

const PageQuestions: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({ espace, location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useQuestions({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <List
        itemList={Item}
        model={
          new QuestionData({
            espaceId: espace.id,
            params: question,
            wheres: {
              parent: '',
            },
          })
        }
        search={location.search}
        subMenu={items}
        tabIndex={tab ?? 0}
        title={t('questions.title')}
        user={user}
      />
    </TemplateEspace>
  );
};

export default requireEspace(PageQuestions);
